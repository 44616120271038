<template>
  <el-main>
    <div class="blodTitle">快应用</div>
    <img :src="imgUrlHead + 'banner.png'" alt="" />
    <div class="blodTitle">授权及发布小程序</div>
    <Empower></Empower>
    <div class="blodTitle">快应用特点</div>
    <div class="fourFunction">
      <div class="left">
        <div class="leftBot">
          <p v-for="(item, index) in fourFun.list1" :key="index">{{ item }}</p>
        </div>
      </div>
      <div class="right">
        <div v-for="(item, index) in fourFun.list2" :key="index" class="functionItem">
          <img :src="imgUrlHead + item.img" alt="" />
          <div style="margin: 20px 0 10px 0">
            <b>{{ item.name }}</b>
          </div>
          <div style="color: #666666; white-space: nowrap">{{ item.desc }}</div>
        </div>
      </div>
    </div>
    <div class="blodTitle">业界快讯</div>
    <div class="operateArr">
      <div v-for="(item, index) in operateArr" :key="index" :style="{ marginBottom: index <= 1 ? '20px' : '' }">
        <img :src="imgUrlHead + item.img" alt="" />
        <div class="right">
          <div class="title">{{ item.title }}</div>
          <div class="bot">{{ item.content }}</div>
        </div>
      </div>
    </div>
    <div class="blodTitle">
      优秀案例
      <span class="subTitle">更多案例</span>
    </div>
    <div class="goodCase">
      <div class="goodCaseItem" v-for="(item, index) in goodCase" :key="index">
        <img :src="imgUrlHead + item.img" alt="" />
        <div>{{ item.name }}</div>
      </div>
    </div>
  </el-main>
</template>

<script>
import { imgUrlHead } from '@/util/config'
import Empower from './sonPage/quickSon/empower.vue'
export default {
  components: {
    Empower
  },
  data() {
    return {
      imgUrlHead: imgUrlHead + 'fastApp/',
      fourFun: {
        list1: [
          '开发成本低：代码量是传统应用的5/1，开发语言简单，主要是js/css;',
          '流量蓝海：华为等手机全场景可实现跨平台跳转打通多元流量池；',
          '业务提升：直达服务/内容、体验好、路径短、提升多维度数据，拉新、促活、提存率;',
          '全局搜索：手机主页下滑、卡片式呈现，直达快应用。',
          '社交媒体平台分享&媒体渠道推广：直接跳转快应用'
        ],
        list2: [
          { name: '无需安装 即点即用', desc: '革命性应用形态，易推广、高留存', img: '01.png' },
          { name: '全球化分发', desc: '已上线超过170+国家和地区', img: '02.png' },
          { name: '全终端接入', desc: '全面支持从小屏幕到大屏幕各种智能终端', img: '03.png' }
        ]
      },
      operateArr: [
        {
          img: 'pic01.png',
          title: '华为快应用支持广告变现，加速商业化进程',
          content: '大部分App经营的主要目的是变现,变现的关键是流量,但App领域经过多年的发展,流量获取并非易事,开发者迫切希望开拓新的流量渠道。'
        },
        {
          img: 'pic02.png',
          title: '华为快应用IDE上线Serverless云服务',
          content:
            '华为快应用IDE正式上线Serverless云开发功能,提供云函数、云数据库、云存储以及认证服务等开发类后端服务。基于Serverless云开发,开发者无需搭建服务器即可快速构建应用后端,聚焦业务逻辑而无需关注琐碎的运维事务,由华为保障云侧服务的高可用性。'
        },
        {
          img: 'pic03.png',
          title: '华为快应用2.0丨新能力、新场景赋能新生态',
          content:
            '快应用相关专家提出："快应用技术经过了过去一年的发展，在许多方面有了巨大的进步。面向C端，我们升级了规范能力，开放了更多HMS能力。面向B端，我们优化了IDE开发工具，在线生成H5快应用、卡片工具。'
        },
        {
          img: 'pic04.png',
          title: '华为快应用支持广告变现，加速商业化进程',
          content: '近年来，如何高效、低成本获客并转化是不少企业在思考的课题。随着AI、大数据、云计算、5G技术的快速发展，一些企业找到了解题方法——利用全场景、多终端，打通多元流量池。'
        }
      ],
      goodCase: [
        { name: '雅兰天丽美妆', img: 'qr.png' },
        { name: '天宜世家家居', img: 'qr.png' },
        { name: '伊乐贝斯女装', img: 'qr.png' },
        { name: '日易惠百货用品', img: 'qr.png' },
        { name: '新稻香农产品', img: 'qr.png' }
      ]
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.el-main {
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px 20px 20px;
  background-color: #fff;
  .blodTitle {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin: 40px 0 20px 0;
    .subTitle {
      color: #5654f3;
      font-size: 14px;
      font-weight: normal;
      cursor: pointer;
      margin-left: 23px;
    }
  }
  & > img {
    width: 100%;
  }
  .fourFunction {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .left {
      flex: 0 0 680px;
      flex-shrink: 0;
      font-size: 16px;
      margin-right: 122px;
      margin-bottom: 20px;
      .leftBot {
        p {
          line-height: 18px;
          position: relative;
          padding-left: 30px;
          &::before {
            width: 8px;
            height: 8px;
            border: 1px solid #000559;
            border-radius: 50%;
            content: '';
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            display: block;
          }
        }
        & > p:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
    .right {
      flex: 1;
      display: flex;
      // flex-wrap: nowrap;
      justify-content: space-around;
      .functionItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
  .operateArr {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    & > div {
      width: 49.5%;
      min-width: 783px;
      flex-shrink: 0;
      border: 1px solid #f2f2f2;
      box-shadow: 0px 0px 16px 0px rgba(105, 138, 201, 0.05);
      display: flex;
      padding: 20px;

      img {
        margin-right: 10px;
      }
      .right {
        padding: 10px 0;
      }
      .title {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 20px;
      }
      .bot {
        margin-top: 17px;
        // padding-right: 50px;
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
      }
    }
  }
  .goodCase {
    display: flex;
    flex-wrap: wrap;
    .goodCaseItem {
      display: flex;
      flex-direction: column;
      font-size: 14px;
      text-align: center;
      flex-shrink: 0;
      margin-right: 185px;
      margin-bottom: 20px;
    }
  }
}
</style>
