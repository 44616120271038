<template>
  <div class="subscribeBox">
    <el-form
      :model="dataInfo"
      :rules="rules"
      ref="ruleForm"
      label-width="150px"
      class="demo-ruleForm"
    >
      <el-form-item label="快应用包名：" prop="packagename">
        <el-input v-model="dataInfo.packagename"></el-input>
        <span class="looktips">需要在华为开发者账号中创建账号</span>
      </el-form-item>
      <el-form-item label="快应用名称：" prop="name">
        <el-input v-model="dataInfo.name"></el-input>
        <span class="looktips">用户协议和隐私政策里面的快应用名称</span>
      </el-form-item>
      <el-form-item label="开发者主体：" prop="company">
        <el-input v-model="dataInfo.company"></el-input>
        <span class="looktips">华为开发者主体公司名称(用户协议和隐私政策)</span>
      </el-form-item>
      <el-form-item label="快应用logo：" prop="logo">
        <div class="listItem">
          <ReadyUploadSource
            :showStyle="{
              width: '218px',
              height: '218px'
            }"
            @getSource="getImg"
            @removeThis="removeImg"
            :path="dataInfo.logo"
          ></ReadyUploadSource>
        </div>
        <p class="logoTips">建议尺寸216px*216px，logo图片</p>
      </el-form-item>
      <el-form-item label="快应用AppID：" prop="app_id">
        <el-input v-model="dataInfo.app_id"></el-input>
      </el-form-item>
      <el-form-item label="客服电话：" prop="mobile">
        <el-input v-model="dataInfo.mobile"></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import ReadyUploadSource from '@/components/readyUploadSource'
export default {
  components: {
    ReadyUploadSource
  },
  props: {
    dataInfo: {
      type: Object,
      default: () => {}
    },
    isHave: {
      type: Object,
      default: () => true
    }
  },
  data() {
    return {
      rules: {
        packagename: [{ required: true, message: '请输入快应用包名', trigger: 'blur' }],
        name: [{ required: true, message: '请输入快应用名称', trigger: 'blur' }],
        company: [{ required: true, message: '请输入开发者主体', trigger: 'blur' }],
        logo: [{ required: true, message: '请输入快应用logo', trigger: 'blur' }],
        app_id: [{ required: true, message: '请输入快应用AppID', trigger: 'blur' }],
        mobile: [{ required: true, message: '请输入客服电话', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 上传图片
    getImg(val) {
      this.dataInfo.logo = val.path
    },
    removeImg() {
      this.dataInfo.logo = ''
    }
  }
}
</script>

<style lang="less" scoped>
.subscribeBox {
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /**/
  }
  &::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #f9f9f9;
  }
  &::-webkit-scrollbar-corner {
    background: #204754;
  }
  .el-form {
    .el-form-item {
      width: 900px;
      /deep/ .el-form-item__content .el-input {
        width: 400px;
      }
    }
  }
}

.logoTips {
  margin-top: 10px;
  color: #a2a2a2;
  font-size: 12px;
}
.listItem {
  display: flex;
}
.imgBox {
  width: 126px;
  height: 126px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-right: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}

.addImg {
  width: 126px;
  height: 126px;
  border: 1px dashed #c0ccda;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 28px;
    color: #8c939d;
  }
}
.looktips {
  color: #9b9b9b;
  font-size: 14px;
  line-height: 40px;
  margin-left: 20px;
}
</style>
